<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    text?: string
    count?: number | string
    showMoreText?: string
    showLessText?: string
    type?: 'letter' | 'word'
    show?: 'inline' | 'modal'
  }>(),
  {
    text: undefined,
    count: 36,
    showMoreText: 'show more',
    showLessText: 'show less',
    amountOfChars: 250,
    type: 'word',
    show: 'inline',
  },
)

const slots = useSlots()

const attrs = useAttrs()
const isExpanded = ref(false)

const text = computed<string>(() => {
  const text = props.text ?? slots.default?.()?.[0]?.children ?? ''

  return text
    ?.toString()
    .replace(/^\s+/gm, '') // remove spaces in the beginning of each line
    .replace(/\s\s+/g, ' ') // remove multiple spaces of a single line
    .replace(/\n(?![A-Z(])/g, ' ') // add space before a line not starting with an uppercase letter or parenthesis
})

const itCanOverflow = computed(() => {
  if (props.type === 'word') {
    const words = text.value.split(' ')
    return words.length > Number(props.count)
  } else {
    return text.value.length > Number(props.count)
  }
})

const toggleExpanded = () => {
  isExpanded.value = !isExpanded.value
}
</script>

<template>
  <div class="flex">
    <p
      class="inline-block grow"
      :class="{
        'inline-block truncate': itCanOverflow && !isExpanded,
      }"
    >
      {{ text }}
    </p>
    <div v-if="itCanOverflow" class="flex items-center">
      <span
        class="border-secondary/20 bg-secondary/5 hover:bg-secondary/20 focus:bg-secondary/20 focus:ring-gray-550/80 ml-1 inline-flex items-center justify-center gap-1 text-nowrap rounded-lg border px-2 py-1 text-xs font-medium text-white transition-all ease-in-out focus:outline-none focus:ring-0"
        role="button"
        tabIndex="0"
        :aria-expanded="isExpanded"
        @keydown.space.prevent="toggleExpanded"
        @keydown.enter.prevent="toggleExpanded"
        @click="toggleExpanded"
      >
        {{ isExpanded ? showLessText : showMoreText }}
      </span>
    </div>
  </div>
</template>
